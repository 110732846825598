import React, { useState } from "react";
import {
  Modal,
  Form,
  Input,
  notification,
  Upload,
  Row,
  Col,
  Radio,
  InputNumber,
} from "antd";
import Joi from "joi";
import { PlusOutlined } from "@ant-design/icons";
import http from "../../helpers/http";
import config from "../../config/config";
import _ from "lodash";
import HtmlEditor from "../../components/HtmlEditor";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const Create = ({ isVisible, handleClose, moduleName }) => {
  const [form] = Form.useForm();
  const [loading, setloading] = useState(false);
  const [formError, setFormError] = useState({});
  const [isVideoVisible, setisVideoVisible] = useState(false);
  const [description, setDescription] = useState();

  const base_url = config.api_url + "admin/homepage/features";
  const image_url = config.api_url + "uploads/homepageFeature/";
  const sizeLimit = 1;

  //validation schema

  const validate = (data) => {
    let schemaObj = {
      title: Joi.required(),
      
    };
    console.log(data);

    const schema = Joi.object(schemaObj).options({ allowUnknown: true });

    const { error } = schema.validate(data, {
      abortEarly: false,
      allowUnknown: true,
      errors: {
        wrap: {
          label: "",
        },
      },
    });
    // console.log(error.details);
    const errors = {};

    if (error) {
      error.details.map((field) => {
        errors[field.path[0]] = field.message;
        return true;
      });
    }

    if (data.bg_image) {
      if (data.bg_image.file.size > sizeLimit * 1024 * 1024) {
        errors["bg_image"] = `File needs to be under ${sizeLimit}MB`;
      }
      let allowedExt = [
        "image/jpeg",
        "image/png",
        "image/svg+xml",
        "image/svg",
      ];
      if (!allowedExt.includes(data.bg_image.file.type)) {
        errors["bg_image"] = "File does not have a valid file extension.";
      }
    }
    if (data.icon) {
      if (data.icon.file.size > sizeLimit * 1024 * 1024) {
        errors["icon"] = `File needs to be under ${sizeLimit}MB`;
      }
      let allowedExt = [
        "image/jpeg",
        "image/png",
        "image/svg+xml",
        "image/svg",
      ];
      if (!allowedExt.includes(data.icon.file.type)) {
        errors["icon"] = "File does not have a valid file extension.";
      }
    }
    if (data.hover_icon) {
      if (data.hover_icon.file.size > sizeLimit * 1024 * 1024) {
        errors["hover_icon"] = `File needs to be under ${sizeLimit}MB`;
      }
      let allowedExt = [
        "image/jpeg",
        "image/png",
        "image/svg+xml",
        "image/svg",
      ];
      if (!allowedExt.includes(data.hover_icon.file.type)) {
        errors["hover_icon"] = "File does not have a valid file extension.";
      }
    }

    return errors ? errors : null;
  };

  const handleSubmit = async (value) => {
    let errors = validate(value);
    setFormError(errors);
    console.log(errors);
    if (Object.keys(errors).length) {
      return;
    }

    setloading(true);
    let formData = new FormData();

    for (const [key, data] of Object.entries(
      _.omit(value, ["bg_image", "icon"])
    )) {
      formData.append(key, data || "");
    }
    if (value.bg_image) {
      formData.append("bg_image", value.bg_image.file);
    }
    if (value.icon) {
      formData.append("icon", value.icon.file);
    }
    if (value.hover_icon) {
      formData.append("hover_icon", value.hover_icon.file);
    }

    const { data } = await http.post(base_url, formData);

    if (data) {
      notification["success"]({
        message: `${moduleName} Added Successfully`,
      });
      setloading(false);

      form.resetFields();

      handleClose();
    }
  };

  const handleTypeChange = (type) => {
    if (type.target.value === "video") {
      setisVideoVisible(true);
    } else {
      setisVideoVisible(false);
    }
  };

  return (
    <Modal
      title={`Add ${moduleName}`}
      visible={isVisible}
      onCancel={handleClose}
      okText="Create"
      cancelText="Cancel"
      confirmLoading={loading}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            values.description = description;
            handleSubmit(values);
          })
          .catch((info) => {});
      }}
      width={700}
    >
      <Form layout="vertical" form={form}>
        <Row gutter={16}>
          <Col xs={24} md={24}>
            <Form.Item
              required
              label="Title:"
              name="title"
              {...(formError.title && {
                help: formError.title,
                validateStatus: "error",
              })}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col xs={24} md={24}>
            <Form.Item
              getValueProps={(i) => {}}
              
              label="Description:"
              name="description"
              {...(formError.description && {
                help: formError.description,
                validateStatus: "error",
              })}
            >
              <HtmlEditor
                id="create-editor"
                textareaName="description"
                height={350}
                onEditorChange={(newText) => setDescription(newText)}
              />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item
              label="Icon: (40 X 40)"
              name="icon"
              {...(formError.icon && {
                help: formError.icon,
                validateStatus: "error",
              })}
            >
              <Upload
                beforeUpload={(file) => {
                  return false;
                }}
                maxCount={1}
                listType="picture-card"
                showUploadList={{
                  showPreviewIcon: false,
                  showRemoveIcon: false,
                }}
              >
                <PlusOutlined />
              </Upload>
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              label="Hover Icon: (40 X 40)"
              name="hover_icon"
              {...(formError.hover_icon && {
                help: formError.hover_icon,
                validateStatus: "error",
              })}
            >
              <Upload
                beforeUpload={(file) => {
                  return false;
                }}
                maxCount={1}
                listType="picture-card"
                showUploadList={{
                  showPreviewIcon: false,
                  showRemoveIcon: false,
                }}
              >
                <PlusOutlined />
              </Upload>
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              label="Background Image: (398 X 362)"
              name="bg_image"
              {...(formError.bg_image && {
                help: formError.bg_image,
                validateStatus: "error",
              })}
            >
              <Upload
                beforeUpload={(file) => {
                  return false;
                }}
                maxCount={1}
                listType="picture-card"
                showUploadList={{
                  showPreviewIcon: false,
                  showRemoveIcon: false,
                }}
              >
                <PlusOutlined />
              </Upload>
            </Form.Item>
          </Col>
        </Row>

       
      </Form>
    </Modal>
  );
};

export default Create;
