import React, { useState } from "react";
import {
  Modal,
  Form,
  Input,
  notification,
  Upload,
  Row,
  Col,
  Radio,
  InputNumber,
  
} from "antd";
import Joi from "joi";
import { PlusOutlined } from "@ant-design/icons";
import http from "../../helpers/http";
import config from "../../config/config";
import _ from "lodash";
import HtmlEditor from "../../components/HtmlEditor";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const Create = ({ isVisible, handleClose, moduleName }) => {
  const [form] = Form.useForm();
  const [loading, setloading] = useState(false);
  const [formError, setFormError] = useState({});
  const [isVideoVisible, setisVideoVisible] = useState(false);
  const [description, setDescription] = useState();

  const base_url = config.api_url + "admin/homepage/brownies";
  const image_url = config.api_url + "uploads/homepageBrownie/";
  const sizeLimit = 1;


  const { TextArea } = Input;
  //validation schema

  const validate = (data) => {
    let schemaObj = {
      title: Joi.required(),
      
    };
    console.log(data);

    const schema = Joi.object(schemaObj).options({ allowUnknown: true });

    const { error } = schema.validate(data, {
      abortEarly: false,
      allowUnknown: true,
      errors: {
        wrap: {
          label: "",
        },
      },
    });
    // console.log(error.details);
    const errors = {};

    if (error) {
      error.details.map((field) => {
        errors[field.path[0]] = field.message;
        return true;
      });
    }

    if (data.image) {
      if (data.image.file.size > sizeLimit * 1024 * 1024) {
        errors["image"] = `File needs to be under ${sizeLimit}MB`;
      }
      let allowedExt = [
        "image/jpeg",
        "image/png",
        "image/svg+xml",
        "image/svg",
      ];
      if (!allowedExt.includes(data.image.file.type)) {
        errors["image"] = "File does not have a valid file extension.";
      }
    }
  

    return errors ? errors : null;
  };

  const handleSubmit = async (value) => {
    let errors = validate(value);
    setFormError(errors);
    console.log(errors);
    if (Object.keys(errors).length) {
      return;
    }

    setloading(true);
    let formData = new FormData();

    for (const [key, data] of Object.entries(
      _.omit(value, ["image"])
    )) {
      formData.append(key, data || "");
    }
    if (value.image) {
      formData.append("image", value.image.file);
    }
   

    const { data } = await http.post(base_url, formData);

    if (data) {
      notification["success"]({
        message: `${moduleName} Added Successfully`,
      });
      setloading(false);

      form.resetFields();

      handleClose();
    }
  };

  const handleTypeChange = (type) => {
    if (type.target.value === "video") {
      setisVideoVisible(true);
    } else {
      setisVideoVisible(false);
    }
  };

  return (
    <Modal
      title={`Add ${moduleName}`}
      visible={isVisible}
      onCancel={handleClose}
      okText="Create"
      cancelText="Cancel"
      confirmLoading={loading}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
           
            handleSubmit(values);
          })
          .catch((info) => {});
      }}
      width={700}
    >
      <Form layout="vertical" form={form}>
        <Row gutter={16}>
          <Col xs={24} md={24}>
            <Form.Item
              required
              label="Title:"
              name="title"
              {...(formError.title && {
                help: formError.title,
                validateStatus: "error",
              })}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col xs={24} md={24}>
            <Form.Item
            
              
              label="Description:"
              name="description"
              {...(formError.description && {
                help: formError.description,
                validateStatus: "error",
              })}
            >
               <TextArea rows={3} />
            </Form.Item>
          </Col>

         
          <Col xs={24} md={12}>
            <Form.Item
              label="Image: (708 X 978)"
              name="image"
              {...(formError.image && {
                help: formError.image,
                validateStatus: "error",
              })}
            >
              <Upload
                beforeUpload={(file) => {
                  return false;
                }}
                maxCount={1}
                listType="picture-card"
                showUploadList={{
                  showPreviewIcon: false,
                  showRemoveIcon: false,
                }}
              >
                <PlusOutlined />
              </Upload>
            </Form.Item>
          </Col>
        </Row>

       
      </Form>
    </Modal>
  );
};

export default Create;
