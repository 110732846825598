import React, { useEffect, useState } from "react";
// import tinymce from "tinymce/tinymce";
import {
  Breadcrumb,
  Row,
  Col,
  Card,
  Form,
  Input,
  notification,
  Upload,
  Button,
  Spin,
  Image,
} from "antd";

import _ from "lodash";
import http from "../../helpers/http";
import config from "../../config/config";
import {
  EditOutlined,
  DownloadOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import Logo from "../../upgrad-logo.svg";

import Joi from "joi";
function Homepage() {
  const [editOpen, setEditOpen] = useState(false);
  const [formError, setFormError] = useState({});
  const [data, setData] = useState({});
  const [loading, setloading] = useState(false);
  const [btnloading, setbtnloading] = useState(false);
  const [isHelpShow, setIsHelpShow] = useState(false);

  //createKey and editKey is used to reset modal data on close

  const [editKey, seteditKey] = useState(Math.random() * 10);

  const moduleName = "Homepage Content";
  const moduleNamePural = "Homepage Content";
  const base_url = config.api_url + "admin/content/homepage"; //without trailing slash
  const image_url = config.api_url + "uploads/content/homepage/"; //with trailing slash
  const sizeLimit = 10;

  const { TextArea } = Input;

  const schema = Joi.object({}).options({ allowUnknown: true });

  const validate = async (value) => {
    const { error } = schema.validate(value, {
      abortEarly: false,
      allowUnknown: true,
      errors: {
        wrap: {
          label: "",
        },
      },
    });

    const errors = {};

    if (error) {
      error.details.map((field) => {
        errors[field.path[0]] = field.message;
        return true;
      });
    }

    if (value.stockGraph) {
      if (value.stockGraph.file.size > sizeLimit * 1024 * 1024) {
        errors["stockGraph"] = `File needs to be under ${sizeLimit}MB`;
      }
      let allowedExt = ["image/jpeg", "image/png"];
      if (!allowedExt.includes(value.stockGraph.file.type)) {
        errors["stockGraph"] = "File does not have a valid file extension.";
      }
    }

    return errors ? errors : null;
  };

  const handleSubmit = async (value) => {
    let errors = await validate(value);

    setFormError(errors);

    if (Object.keys(errors).length) {
      return;
    }

    setbtnloading(true);
    let formData = new FormData();
    for (const [key, data] of Object.entries(_.omit(value, ["stockGraph"]))) {
      formData.append(key, data || "");
    }

    if (value.stockGraph) {
      formData.append("stockGraph", value.stockGraph.file);
    }

    const { data } = await http.put(base_url, formData);

    if (data) {
      notification["success"]({
        message: `${moduleName} Updated Successfully`,
      });
      setbtnloading(false);
      form.resetFields();
      fetchData();
    }
  };

  const fetchData = async (id) => {
    setloading(true);
    const { data } = await http.get(base_url);

    if (data) {
      setData(data);
    }
    setloading(false);
  };

  const handleEditClose = () => {
    seteditKey((state) => {
      return state + Math.random() * 2;
    });
    setData({});
    setEditOpen(false);
  };

  const [form] = Form.useForm();
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    form.setFieldsValue(data);
  }, [data]);

  if (loading) {
    return (
      <div style={{ textAlign: "center" }}>
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
        <Breadcrumb.Item>{moduleNamePural}</Breadcrumb.Item>
      </Breadcrumb>

      <Row>
        <Col span={24}>
          <div className="site-card-border-less-wrapper">
            <Card
              title={moduleNamePural}
              bordered={true}
              // extra={
              //   <QuestionCircleOutlined onClick={() => setIsHelpShow(true)} />
              // }
              // style={{ boxShadow: "10px 0px 15px rgba(0,0,0,0.1)" }}
            >
              <Row style={{ marginBottom: "20px" }}></Row>

              <Form
                form={form}
                onFinish={handleSubmit}
                // labelCol={{ span: 8 }}
                // wrapperCol={{ span: 16 }}
                layout="vertical"
              >
                <Row gutter={16}>
                  <Col xs={24} md={8}>
                    <Form.Item
                      label="Feature Section Title:"
                      name="sec_two_title"
                      {...(formError.sec_two_title && {
                        help: formError.sec_two_title,
                        validateStatus: "error",
                      })}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  {/* <Col xs={24} md={8}>
                    <Form.Item
                      label="Section Three Title:"
                      name="sec_three_title"
                      {...(formError.sec_three_title && {
                        help: formError.sec_three_title,
                        validateStatus: "error",
                      })}
                    >
                      <Input />
                    </Form.Item>
                  </Col> */}
                  {/* <Col xs={24} md={8}>
                    <Form.Item
                      label="Section Five Title:"
                      name="sec_five_title"
                      {...(formError.sec_five_title && {
                        help: formError.sec_five_title,
                        validateStatus: "error",
                      })}
                    >
                      <Input />
                    </Form.Item>
                  </Col> */}
                  <Col xs={24} md={8}>
                    <Form.Item
                      label="Living Feature Section Title:"
                      name="sec_six_title"
                      {...(formError.sec_six_title && {
                        help: formError.sec_six_title,
                        validateStatus: "error",
                      })}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={8}>
                    <Form.Item
                      label="Living Feature Section Sub Title:"
                      name="sec_six_sub_title"
                      {...(formError.sec_six_sub_title && {
                        help: formError.sec_six_sub_title,
                        validateStatus: "error",
                      })}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={8}>
                    <Form.Item
                      label="Gallery Section Title:"
                      name="sec_seven_title"
                      {...(formError.sec_seven_title && {
                        help: formError.sec_seven_title,
                        validateStatus: "error",
                      })}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={8}>
                    <Form.Item
                      label="Gallery Section Sub Title:"
                      name="sec_seven_sub_title"
                      {...(formError.sec_seven_sub_title && {
                        help: formError.sec_seven_sub_title,
                        validateStatus: "error",
                      })}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={8}>
                    <Form.Item
                      label="Brownies Section Title:"
                      name="sec_eight_title"
                      {...(formError.sec_eight_title && {
                        help: formError.sec_eight_title,
                        validateStatus: "error",
                      })}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={8}>
                    <Form.Item
                      label="Brownies Section Sub Title:"
                      name="sec_eight_sub_title"
                      {...(formError.sec_eight_sub_title && {
                        help: formError.sec_eight_sub_title,
                        validateStatus: "error",
                      })}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={8}>
                    <Form.Item
                      label="Brownies Section Description:"
                      name="sec_eight_desc"
                      {...(formError.sec_eight_desc && {
                        help: formError.sec_eight_desc,
                        validateStatus: "error",
                      })}
                    >
                      <TextArea rows={3} />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={8}>
                    <Form.Item
                      label="Instagram Section Title:"
                      name="sec_nine_title"
                      {...(formError.sec_nine_title && {
                        help: formError.sec_nine_title,
                        validateStatus: "error",
                      })}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={8}>
                    <Form.Item
                      label="Instagram Section Sub Title:"
                      name="sec_nine_sub_title"
                      {...(formError.sec_nine_sub_title && {
                        help: formError.sec_nine_sub_title,
                        validateStatus: "error",
                      })}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={8}>
                    <Form.Item
                      label="Instagram Section Description:"
                      name="sec_nine_desc"
                      {...(formError.sec_nine_desc && {
                        help: formError.sec_nine_desc,
                        validateStatus: "error",
                      })}
                    >
                      <TextArea rows={3} />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={8}>
                    <Form.Item
                    // wrapperCol={{ offset: 8, span: 16 }}
                    >
                      <Button
                        type="danger"
                        htmlType="submit"
                        loading={btnloading}
                      >
                        Update
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
              <Image
                width={200}
                style={{
                  display: "none",
                }}
                src={Logo}
                preview={{
                  visible: isHelpShow,
                  src: Logo,
                  onVisibleChange: (value) => {
                    setIsHelpShow(value);
                  },
                }}
              />
            </Card>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default Homepage;
