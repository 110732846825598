import React, { useState } from "react";
import { Form, Input, Button, Row, Col, Card, notification } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import Joi from "joi-browser";
import http from "../../helpers/http";

import config from "../../config/config";
import Logo from "../../upgrad-logo.svg";
const Login = (props) => {
  const [form] = Form.useForm();
  const [loading, setloading] = useState(false);
  const [formError, setFormError] = useState({});

  const base_url = config.api_url + "auth/login";

  //validation schema
  const schema = Joi.object({
    email: Joi.required(),

    password: Joi.required(),
  }).options({ allowUnknown: true });

  const validate = (data) => {
    const { error } = Joi.validate(data, schema, {
      abortEarly: false,
      allowUnknown: true,
    });

    const errors = {};

    if (error) {
      error.details.map((field) => {
        errors[field.path[0]] = field.message;
        return true;
      });
    }

    return errors ? errors : null;
  };

  const handleSubmit = async (value) => {
    let errors = validate(value);

    setFormError(errors);
    if (Object.keys(errors).length) {
      return;
    }

    setloading(true);

    const data = await http.post(base_url, value);

    if (data) {
      const token = data.data.token;
      localStorage.setItem(config.jwt_store_key, token);
      notification["success"]({
        message: `Login Successfully`,
      });
      window.location = "/homepage/features";

      form.resetFields();
    }
    setloading(false);
  };

  return (
    <div className="login_form_wrapper">
      <Row>
        <Col span={12} className="gutter-row">
          <Card
            title=""
            className="login-box"
            bordered={false}
            style={{ width: 400 }}
          >
            <figure className="raymond_login_logo_wrapper">
              <img src={Logo} alt="Raymond logo" />
            </figure>
            <Form onFinish={handleSubmit} form={form}>
              <Form.Item
                name="email"
                {...(formError.email && {
                  help: formError.email,
                  validateStatus: "error",
                })}
              >
                <Input prefix={<UserOutlined />} placeholder="Email" />
              </Form.Item>
              <Form.Item
                name="password"
                {...(formError.password && {
                  help: formError.password,
                  validateStatus: "error",
                })}
              >
                <Input.Password
                  prefix={<LockOutlined />}
                  placeholder="Password"
                />
              </Form.Item>

              <Form.Item>
                <Button
                  type="primary"
                  size="large"
                  htmlType="submit"
                  loading={loading}
                >
                  Log in
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Login;
