import React, { useState, useEffect } from "react";
import { List, Modal ,notification} from "antd";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
// import datas from "./data";

// import VirtualList from "rc-virtual-list";
import http from "../../helpers/http";
import config from "../../config/config";
import { DragHandle } from "../../components/DragHandle";

const Ordering = ({ isOpen, onClose, datas,moduleName }) => {
  const [datalist, setdatalist] = useState([]);
  const [loading, setloading] = useState(false);


  const base_url = config.api_url + "admin/homepage/features";  //without trailing slash

  



  useEffect(() => {
    console.log(datas)
    setdatalist(datas);
  }, [datas]);



  const handleOrder = async(updatedData)=>{
    setloading(true);
    const {data}= await http.put(`${base_url}/sort`,updatedData);
    console.log(data);
    if(data){
      notification["success"]({
        message: `${moduleName} Sorting Updated Successfully`,
      });
      onClose();
     
    }
    setloading(false);
}


  return (
    <>
      <Modal
        title="Ordering"
        visible={isOpen}
        onOk={() => {
          handleOrder(datalist);
        }}
        okText="Update"
      confirmLoading={loading}

        onCancel={onClose}
      >
        <DragDropContext
          onDragEnd={(param) => {
            const id = param.draggableId;
            const srcI = param.source.index;
            const sort_order = param.destination?.index;

            console.log(param);
            if (sort_order != undefined) {
              // handleOrder(id,sort_order);
              let temparray = [...datalist];
              temparray.splice(sort_order, 0, temparray.splice(srcI, 1)[0]);
              console.log(temparray);
              setdatalist(temparray);
              //   datas.saveList(datalist);
            }
          }}
        >
          <div>
            <Droppable droppableId="droppable-1">
              {(provided, _) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  style={{ maxHeight: 400, overflow: "auto" }}
                >
                  <List
                    // footer={<div>Footer</div>}
                    bordered
                    dataSource={datalist}
                    renderItem={(item, i) => (
                      <>
                        <Draggable
                          key={item._id}
                          draggableId={item._id}
                          index={i}
                        >
                          {(provided, snapshot) => (
                            <div  ref={provided.innerRef}>
                            <List.Item
                             
                              {...provided.draggableProps}
                              style={{
                                ...provided.draggableProps.style,
                                boxShadow: snapshot.isDragging
                                  ? "0 0 .4rem #666"
                                  : "none",
                              }}
                            >
                              <span>{item.title || "-"}</span>
                              <DragHandle {...provided.dragHandleProps} />
                            </List.Item>
                            </div>
                          )}
                        </Draggable>
                      </>
                    )}
                  />

                  {/* {datalist.map((item, i) => (
                  <Draggable
                    key={item.id}
                    draggableId={"draggable-" + item.id}
                    index={i}
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        style={{
                          ...provided.draggableProps.style,
                          boxShadow: snapshot.isDragging
                            ? "0 0 .4rem #666"
                            : "none",
                        }}
                      >
                        <DragHandle {...provided.dragHandleProps} />
                        <span>{item.title}</span>
                      </div>
                    )}
                  </Draggable>
                ))} */}

                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </div>
        </DragDropContext>
      </Modal>
    </>
  );
};

export default Ordering;
