import React, { useState } from "react";
import "./App.less";
import { Layout, Menu, Modal, Drawer, Button } from "antd";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
} from "react-router-dom";
import {
  ExclamationCircleOutlined,
  MenuOutlined,
  HolderOutlined,
} from "@ant-design/icons";

import Sliders from "./pages/sliders/Index";
import HomepageFeatures from "./pages/HomepageFeatures/Index";
import HomepageGalleries from "./pages/HomepageGalleries/Index";
import HomepageBrownies from "./pages/HomepageBrownies/Index";
import HomepageLivingFeatures from "./pages/HomepageLivingFeatures/Index";
import AmenityCategories from "./pages/AmenityCategories/Index";
import AmenityGalleries from "./pages/AmenityGalleries/Index";
import Amenities from "./pages/Amenities/Index";
import Teams from "./pages/Teams/Index";
import HomepageContent from "./pages/Content/Homepage";
import HomepagePerks from "./pages/Content/HomepagePerks";
import AmenityContent from "./pages/Content/Amenity";
import NotFound from "./pages/NotFound/Index";

import Logout from "./components/Logout";

function MainLayout(props) {
  const { Header, Content, Footer, Sider } = Layout;
  const { SubMenu } = Menu;
  const { confirm } = Modal;
  const [collapsed, setCollapsed] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedKeys, setselectedKeys] = useState(0);
  const onClose = () => {
    setOpen(false);
  };
  const onCollapse = (collapsed) => {
    setCollapsed(!collapsed);
  };

  const showLogoutConfirm = (id) => {
    confirm({
      title: `Are you sure you want to logout?`,
      icon: <ExclamationCircleOutlined />,
      content: "",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",

      onOk() {
        // return handleDelete(id);
        props.history.push("/logout");
      },
      onCancel() {},
    });
  };

  const MenuComponent = (
    <Menu theme="light" mode="inline">
      <SubMenu key="Homepage" icon={<HolderOutlined />} title="Homepage">
        {/* <Menu.Item key="1">
          <Link to="/homepage/sliders">Sliders</Link>
        </Menu.Item> */}
        <Menu.Item key="2">
          <Link to="/homepage/features">Features</Link>
        </Menu.Item>
        <Menu.Item key="10">
          <Link to="/homepage/galleries">Galleries</Link>
        </Menu.Item>
        {/* <Menu.Item key="3">
          <Link to="/homepage/brownies">Brownies</Link>
        </Menu.Item> */}
        <Menu.Item key="11">
          <Link to="/homepage/living-features">Living Features</Link>
        </Menu.Item>
        {/* <Menu.Item key="12">
          <Link to="/homepage/perks">Perks</Link>
        </Menu.Item> */}
        <Menu.Item key="4">
          <Link to="/homepage/content">Content</Link>
        </Menu.Item>
      </SubMenu>
      {/* <SubMenu key="AboutUs" icon={<HolderOutlined />} title="About Us">
        <Menu.Item key="5">
          <Link to="/about-us/teams">Teams</Link>
        </Menu.Item>
      </SubMenu> */}
      <SubMenu key="Amenity" icon={<HolderOutlined />} title="Amenities">
        <Menu.Item key="6">
          <Link to="/amenity/categories">Category</Link>
          
        </Menu.Item>
        <Menu.Item key="7">
          <Link to="/amenities">Amenities</Link>
          
        </Menu.Item>
        <Menu.Item key="8">
          <Link to="/amenity/galleries">Gallery</Link>
          
        </Menu.Item>
        <Menu.Item key="9">
        <Link to="/amenity/content">Content</Link>
          
        </Menu.Item>
      </SubMenu>
    </Menu>
  );
  return (
    <Router>
      <Layout style={{ minHeight: "100vh" }}>
        <Sider
          // theme="dark"
          // collapsible
          // collapsed={collapsed}
          // onCollapse={() => {
          //   onCollapse(collapsed);
          // }}
          theme="light"
          breakpoint="lg"
          collapsedWidth="0"
          onBreakpoint={(broken) => {
            console.log(broken);
          }}
          onCollapse={(collapsed, type) => {
            console.log(collapsed, type);
          }}
          trigger={null}
        >
          {/* <div className="logo">
            <span>Raymond</span>
            <img src={Logo} className="img-fluid" />
          </div> */}
          {MenuComponent}
        </Sider>
        <Layout className="site-layout">
          <Header className="site-layout-background" style={{ padding: 0 }}>
            <Button
              className="menu"
              type="primary"
              icon={<MenuOutlined />}
              onClick={() => setOpen(true)}
            />
            <Menu theme="dark" mode="horizontal">
              <Menu.Item key="1" >
                <Link
                  onClick={() => {
                    showLogoutConfirm();
                  }}
                >
                  Logout
                </Link>
              </Menu.Item>
            </Menu>
          </Header>
          <Content style={{ margin: "0 16px" }}>
            <Switch>
              <Route exact path="/" component={Sliders} />

              <Route exact path="/homepage/sliders" component={Sliders} />
              <Route
                exact
                path="/homepage/features"
                component={HomepageFeatures}
              />
              <Route
                exact
                path="/homepage/galleries"
                component={HomepageGalleries}
              />
              <Route
                exact
                path="/homepage/brownies"
                component={HomepageBrownies}
              />
              <Route
                exact
                path="/homepage/living-features"
                component={HomepageLivingFeatures}
              />
              <Route
                exact
                path="/homepage/content"
                component={HomepageContent}
              />
              <Route
                exact
                path="/homepage/perks"
                component={HomepagePerks}
              />
              <Route
                exact
                path="/amenity/categories"
                component={AmenityCategories}
              />
              <Route
                exact
                path="/amenity/galleries"
                component={AmenityGalleries}
              />
              <Route
                exact
                path="/amenities"
                component={Amenities}
              />
              <Route exact path="/amenity/content" component={AmenityContent} />

              <Route exact path="/about-us/teams" component={Teams} />

              <Route exact path="/logout" component={Logout} />
              <Route  path="*" component={NotFound} />
            </Switch>
          </Content>
          <Footer style={{ textAlign: "center" }}>
            Design & Developed by Togglehead
          </Footer>
        </Layout>

        <Drawer title="Menu" placement="left" onClose={onClose} open={open}>
          {MenuComponent}
        </Drawer>
      </Layout>
    </Router>
  );
}

export default MainLayout;
