import React, { useState, useEffect } from "react";
import {
  Modal,
  Form,
  Input,
  notification,
  Upload,
  Row,
  Col,
  Radio,
  InputNumber,
  Select,
} from "antd";
import { EditOutlined } from "@ant-design/icons";
import Joi from "joi-browser";
import _ from "lodash";
import http from "../../helpers/http";
import config from "../../config/config";
import HtmlEditor from "../../components/HtmlEditor";

function Edit({ isVisible, handleClose, dbData, moduleName,categories }) {
  const [form] = Form.useForm();
  const [loading, setloading] = useState(false);
  const [formError, setFormError] = useState({});
  const [isVideoVisible, setisVideoVisible] = useState(false);
  const [description, setDescription] = useState();


  const base_url = config.api_url + "admin/amenities";
  const image_url = config.api_url + "uploads/amenity/";
  const sizeLimit = 1;


  const { TextArea } = Input;
  const { Option } = Select;
  // form.setFieldsValue(_.omit(dbData, ["image", "thumbnail"]));

  const validate = (value) => {
    let schemaObj = {
      category: Joi.required(),
      
    };
    console.log(value);

    const schema = Joi.object(schemaObj).options({ allowUnknown: true });

    const { error } = Joi.validate(value, schema, {
      abortEarly: false,
      allowUnknown: true,
    });

    const errors = {};

    if (error) {
      error.details.map((field) => {
        errors[field.path[0]] = field.message;
        return true;
      });
    }

    // if (value.image) {
    //   if (value.image.file.size > sizeLimit * 1024 * 1024) {
    //     errors["image"] = `File needs to be under ${sizeLimit}MB`;
    //   }
    //   let allowedExt = [
    //     "image/jpeg",
    //     "image/png",
    //     "image/svg+xml",
    //     "image/svg",
    //   ];
    //   if (!allowedExt.includes(value.image.file.type)) {
    //     errors["image"] = "File does not have a valid file extension.";
    //   }
    // }
   

    return errors ? errors : null;
  };

  const handleSubmit = async (value) => {
    let errors = validate(value);
    setFormError(errors);

    if (Object.keys(errors).length) {
      return;
    }

    setloading(true);
    let formData = new FormData();

    for (const [key, data] of Object.entries(
      _.omit(value, ["image"])
    )) {
      formData.append(key, data || "");
    }
    // if (value.image) {
    //   formData.append("image", value.image.file);
    // }
   

    const { data } = await http.put(`${base_url}/${dbData._id}`, formData);

    if (data) {
      notification["success"]({
        message: `${moduleName} Updated Successfully`,
      });
      setloading(false);
      form.resetFields();
      handleClose();
    }
  };

  const handleTypeChange = (type) => {
    if (type.target.value === "video") {
      setisVideoVisible(true);
    } else {
      setisVideoVisible(false);
    }
  };

  useEffect(() => {
    form.setFieldsValue(_.omit(dbData, ["image","description"]));
    setDescription(() => {
      return dbData.description;
    });
   
  }, [dbData]);

  return (
    <Modal
      title={`Edit ${moduleName}`}
      visible={isVisible}
      onCancel={handleClose}
      okText="Update"
      cancelText="Cancel"
      confirmLoading={loading}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            values.description = description;
           
            handleSubmit(values);
          })
          .catch((info) => {});
      }}
      width={1000}
    >
      <Form layout="vertical" form={form}>
      <Row gutter={16}>
          <Col xs={24} md={8}>
            <Form.Item
              required
              label="Category:"
              name="category"
              {...(formError.category && {
                help: formError.category,
                validateStatus: "error",
              })}
            >
              <Select
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                {categories &&
                  categories.map((data) => {
                    return <Option value={data._id}>{data.title}</Option>;
                  })}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} md={8}>
            <Form.Item
              
              label="Title:"
              name="title"
              {...(formError.title && {
                help: formError.title,
                validateStatus: "error",
              })}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} md={8}>
            <Form.Item
              
              label="Sub Title:"
              name="sub_title"
              {...(formError.sub_title && {
                help: formError.sub_title,
                validateStatus: "error",
              })}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col xs={24} md={24}>
            <Form.Item
              getValueProps={(i) => {}}
              
              label="Description:"
              name="description"
              {...(formError.description && {
                help: formError.description,
                validateStatus: "error",
              })}
            >
              <HtmlEditor
                id="edit-editor"
                initialValue={dbData.description}
                textareaName="description"
                height={350}
                onEditorChange={(newText) => setDescription(newText)}
              />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item
              label="Button Text:"
              name="btn_text"
              {...(formError.btn_text && {
                help: formError.btn_text,
                validateStatus: "error",
              })}
            >
              <Input  />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              label="Button URL:"
              name="btn_url"
              {...(formError.btn_url && {
                help: formError.btn_url,
                validateStatus: "error",
              })}
            >
              <Input  />
            </Form.Item>
          </Col>
        
          <Col xs={24} md={12}>
            <Form.Item
              label="Button 2 Text:"
              name="btn2_text"
              {...(formError.btn2_text && {
                help: formError.btn2_text,
                validateStatus: "error",
              })}
            >
              <Input  />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              label="Button 2 URL:"
              name="btn2_url"
              {...(formError.btn2_url && {
                help: formError.btn2_url,
                validateStatus: "error",
              })}
            >
              <Input />
            </Form.Item>
          </Col>
         

         

         
         
        </Row>
      </Form>
    </Modal>
  );
}

export default Edit;
